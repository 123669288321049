<template>
  <div class="leaderboard-container">
    <h3 class="section-title w-full mb-3 font-black md:font-normal">
      Activity
    </h3>
    <div class="h-[491px] lg:h-[698px] overflow-y-hidden overflow-x-auto">
      <ListActivityGame
        scroll-disabled
        :max-count="10"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.section-title {
  font-family: Inter;
  line-height: 23px;
  letter-spacing: 0em;
}
</style>
