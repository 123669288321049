<template>
  <div class="action-block-container mt-2">
    <div class="title flex sm:font-[500] font-[700]">
      <SvgIcon
        v-if="icon"
        :icon="icon"
        width="17"
        height="17"
        class="my-auto mr-2"
      />
      {{ title }}
    </div>
    <div v-if="image" class="image my-[10px]">
      <img :src="createImgUrl()" class="cursor-pointer rounded-lg" @click.prevent.stop="handleOpenRegisterModal">
    </div>
    <div class="message-container mb-[14px]">
      <div class="heading mb-[5px]">
        {{ messageHeading }}
      </div>
      <div class="message text-slate-light">
        {{ message }}
      </div>
    </div>
    <div v-if="actionLabel" class="actions mt-auto">
      <ButtonButton
        class="w-full"
        size="sm"
        @click.prevent.stop="handleOpenRegisterModal"
      >
        {{ actionLabel }}
      </ButtonButton>
    </div>
  </div>
</template>
<script setup>
import { useUserAuth } from '~/composables/useUserAuth';

const props = defineProps({
  icon: {
    type: String,
    default: null,
  },
  actionLabel: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
  image: {
    type: String,
    default: null,
  },
  messageHeading: {
    type: String,
    default: null,
  },
  message: {
    type: String,
    default: null,
  },
});

const { handleOpenRegisterModal, } = useUserAuth();

function createImgUrl() {
  return new URL(`../../assets/img/cms/${props.image}.png`, import.meta.url).href;
}
</script>
<style lang="scss">
.action-block-container {
  height: auto;
  display: flex;
  flex-direction: column;

  .message {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
  }

  > * {
    width: 100%;
  }
}
</style>
